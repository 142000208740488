import React from 'react'
import InputMask from 'react-input-mask'

export default function InputDefault({
    label,
    type = "text",
    placeholder,
    name,
    value,
    mask,
    maskChar,
    required,
    onChange = () => {},
})
{

    return(
        <label className="input-default">

            {label && <span>{label}</span>}

            <InputMask 
                type={type} 
                onChange={(e) => onChange(name, e.target.value)} 
                value={value} 
                placeholder={placeholder}
                mask={mask}
                maskChar={maskChar}
                required={required}
            />
        </label>   
    )

}