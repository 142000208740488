import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

//components
import { api } from '../../Api/app'
import messageStore from '../../Store/MessageStore'

import { useGlobal } from '../../Context/GlobalContext'

//scss
import './Profile.scss'
import InputDefault from '../../Components/Form/InputDefault'
import ButtonDefault from '../../Components/Form/ButtonDefault'

export default function Profile(props) {
    
    const history = useHistory()

    const { changePage, setUser, user } = useGlobal()

    const [DTOUser, setDTOUser] = useState({
        foto: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAYFBMVEVmZmb///9VVVVeXl5iYmJaWlpcXFzR0dFWVlZwcHD8/PysrKzf39/GxsZsbGzt7e2Ojo719fXn5+d+fn6VlZWlpaWEhITAwMDU1NS4uLjc3NyLi4udnZ1vb293d3fLy8uj0/C3AAAEW0lEQVR4nO2b2XriMAyFwUvIAgFCWAItff+3HJjIIWUoYwcXLHP+yzbhkyL7yLbk0QgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAv4aSidbihNaJVK+2xjdSy3q7q5bjE8tqt61Pf3i1Tf5Qumyq8RVVU+o44qhEPb32rmVaiwhcTBfz2+6dmS/SV9v3ILL8IXpdFEvWc1Gs77t3Zi1ebeVw9KTnSD4pFpkUQmaLYpL3/jHRr7ZzIEr1Zt9sI7rsd8qIYjPrzUTFUmuUWnbBK3Ry5YNKdNGFccnSw4t/W3lTSKTcXjx8tnWPo834XK5+nGN6ZYI4ZzcPhdGX6fXg7KO0ySITZloq15aGdx9izSsfltaB6Twsn2GXL1IaeVOLgSfMs4xWbWpB+pLYPJ2Q3C74SKkgBV1ZmaxWpKRsdEbVlP8stV9TPqy5hJC0P7fWRdmmwz2XZEgSWtg7WLASUtm05jrEQ7dvNDxyoW7PX2ZWEtqStHuLiscYlW04Ng6SoTbtOywiSBqaO4m+yPnoKG2CJg4j9DRGJ7Sx+i2rPJLsHDX0DOnozumrvAjSGLeFFy3uWKiMbpeWmdtbWbt45eCgGCSIJL0clqNikKnD3noJ7+JgvEM0epGJPk1En+ijX6pFv9iOfrv0Bhve2I8soj90GnxsOOUxQt/g4Df6o/uu+JJbhVBTmZdR8SX68ln8BdD4S9i9JoR7TZN8mxB6bSR5nG0k8TcCxd/KFX0z3ij6dspR/A2xFi3Ne94tzSOVJvt7/k0z1o33SizuundmvmZ7RUSJ+s6Nggt5kbKMol79c9vlRxc/+AmNSr6+O1HNisWxPF/OKo+HYnalPVXGbJzqQz/TLbcbreXlyplSUqer7/eZClZBFNue6bOVuHWd7uRk2fQ+w5TPnTuVXAZgXsg7Tdsy7elQnjHxUJXdKnvcpP+ZWyepvTy9YTERVdYZvFcWFqu06V6oGZTPev7VlsdIyWc3TuvgY6jMcdN4PrKeUirtcsohdA+lmVFfTrKfdtuOY9hKYw45x41jWktqo6VBh9BUGcaF8ymu3Jh8GHDGV4cuO7i/LE0M3doXnkqSD5l/3dtmHjq22DwPTVpYDRxk6YPv/zamk2A8WAdN2e0jTCU15j1QBvskJQ2y0GTqupMHrEuagHXGBPAh29J2nWBXOX0upqr72Mc3w2Ad3izUey/Th8ZBgPV65+aY25gQ2vUvPBFTCnt48qTtYmEWmsyQOrg0qN2GGsHcmhWfwNHfZqf9JZdmxSdA333p4btT2T6wxjwqsfiwir5VYI1rdJfg4GFcmc6uoBz0alQaYKJQHx6HFS0ZglrMUBb0k7zkLLxMSLcC/GwCVIB3KEja/TRE0slOUB3qdF/p6OfX2kVDUPeYaAHpqSOS1u0hbev93v4L8C5h9A6OxF/C/DUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADg7fgDyhYmCCEbhAQAAAAASUVORK5CYII=',
        NomeUsuario: user.NomeUsuario,
        EmailUsuario: user.EmailUsuario,
        CelularUsuario: user.CelularUsuario,
        TelefoneUsuario: user.TelefoneUsuario,
    })

    const [DTOPassword, setDTOPassword] = useState({
        SenhaAtual: '',
        NovaSenha: '',
        ConfirmarNovaSenha: '',
    })

    useEffect(() => {
        changePage('name', 'Minha Conta')
    }, [])

    function onChangeDTOUser(name, value)
    {
        DTOUser[name] = value
        setDTOUser({...DTOUser})
    }

    async function onSubmitUser(e)
    {

        try {

            e.preventDefault()

            if ( !DTOUser.NomeUsuario ) throw "Nome completo é obrigatório"
            if ( !DTOUser.EmailUsuario ) throw "E-mail completo é obrigatório"
            if ( !DTOUser.CelularUsuario ) throw "Celular é obrigatório"

            setUser({...DTOUser})
            messageStore.addSuccess('Dados do usuário alterados com sucesso.')

        } catch ( err ) {

            messageStore.addError( err )

        }

    }

    function onChangeDTOPassword(name, value)
    {
        DTOPassword[name] = value
        setDTOUser({...DTOUser})
    }

    async function onSubmitPassword(e)
    {

        try {

            e.preventDefault()

            if ( !DTOPassword.SenhaAtual ) throw "Digite a senha atual corretamente."
            if ( !DTOPassword.NovaSenha ) throw "Digite a nova senha corretamente."
            if ( !DTOPassword.ConfirmarNovaSenha ) throw "Confirme a nova senha corretamente."
            if ( DTOPassword.ConfirmarNovaSenha !== DTOPassword.NovaSenha ) throw "Confirmação de senha incorreta."

            messageStore.addSuccess('Senha alterada com sucesso.')

        } catch ( err ) {

            messageStore.addError( err )

        }

    }

    return(

        <div id="profile">

            <h2>Olá, <b>{user.NomeUsuario}</b></h2>

            <div className="row forms">

                <div className="form-default">

                    <div className="head">
                        <p>Informações do usuário</p>
                    </div>

                    <form onSubmit={onSubmitUser}>

                        <div className="row">
                            <InputDefault 
                                label="Nome completo*" 
                                onChange={onChangeDTOUser} 
                                name="NomeUsuario" 
                                value={DTOUser.NomeUsuario}
                            />
                            <InputDefault 
                                label="Endereço de e-mail*" 
                                placeholder="nome@exemplo.com.br"
                                type="email" 
                                onChange={onChangeDTOUser} 
                                name="EmailUsuario" 
                                value={DTOUser.EmailUsuario} 
                            />
                        </div>

                        <div className="row">
                            <InputDefault 
                                label="Celular*" 
                                placeholder="(99) 99999-9999" 
                                type="tel"
                                mask="(99) 99999-9999"
                                onChange={onChangeDTOUser} 
                                name="CelularUsuario" 
                                value={DTOUser.CelularUsuario}
                            />
                            <InputDefault 
                                label="Telefone" 
                                placeholder="(99) 9999-9999" 
                                type="tel"
                                mask="(99) 9999-9999"
                                onChange={onChangeDTOUser} 
                                name="TelefoneUsuario" 
                                value={DTOUser.TelefoneUsuario}
                            />
                        </div>        

                        <ButtonDefault>Confirmar</ButtonDefault> 

                    </form>

                </div>

                <div className="form-default">

                    <div className="head">
                        <p>Alterar senha</p>
                    </div>

                    <form onSubmit={onSubmitPassword}>

                        <div className="row">
                            <InputDefault type="password" label="Senha atual" name="SenhaAtual" onChange={onChangeDTOPassword}/>
                        </div>

                        <div className="row">
                            <InputDefault type="password" label="Nova senha" name="NovaSenha" onChange={onChangeDTOPassword}/>
                            <InputDefault type="password" label="Confirmar nova senha" name="ConfirmarNovaSenha" onChange={onChangeDTOPassword}/>
                        </div>        

                        <ButtonDefault>Confirmar</ButtonDefault> 

                    </form>

                </div>

            </div>
            
        </div>

    )

}