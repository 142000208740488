import React from 'react'
import { Link } from 'react-router-dom'

export default function SideBar({data = [], pathname})
{

    return(
        <ul>

            { data.map((row, key) =>
                <li key={key} className={`${pathname === row.to ? 'active' : 'inactive'}`}>
                    <Link to={row.to}>
                        {row.icon}
                        <span>{row.name}</span>
                    </Link>
                </li>
            )}
            
        </ul>
    )

}