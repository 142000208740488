import React from 'react'
import { useGlobal } from '../../Context/GlobalContext'

export default function Profile()
{

    const { user } = useGlobal()

    return(
        <div className="profile-user">
            <img alt="Foto do usuário" src={user.foto}/>
            <span>{user.NomeUsuario}</span>
        </div>
    )

}