import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RequestAPI } from '../../Api/app';
import { useGlobal } from '../../Context/GlobalContext';
import messageStore from '../../Store/MessageStore';

import './Login.scss';

export default function Login() {
    const history = useHistory();

    const [option, setOption] = useState('cliente');

    const [load, setLoad] = useState(false);
    const [Login, setLogin] = useState('');
    const [Senha, setSenha] = useState('');

    const { setUser } = useGlobal();

    useEffect(() => {
        window.localStorage.removeItem('token');
    }, []);

    async function handleOnSubmit(e) {
        e.preventDefault();
        try {
            setLoad(true);

            const response = await RequestAPI.post('Login', {
                Login: Login,
                Senha: Senha,
            });

            setLoad(false);

            if (response.data.Sucesso === false) throw response.data.MsgErro;

            response.data.ObjRetorno.foto =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAYFBMVEVmZmb///9VVVVeXl5iYmJaWlpcXFzR0dFWVlZwcHD8/PysrKzf39/GxsZsbGzt7e2Ojo719fXn5+d+fn6VlZWlpaWEhITAwMDU1NS4uLjc3NyLi4udnZ1vb293d3fLy8uj0/C3AAAEW0lEQVR4nO2b2XriMAyFwUvIAgFCWAItff+3HJjIIWUoYwcXLHP+yzbhkyL7yLbk0QgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAv4aSidbihNaJVK+2xjdSy3q7q5bjE8tqt61Pf3i1Tf5Qumyq8RVVU+o44qhEPb32rmVaiwhcTBfz2+6dmS/SV9v3ILL8IXpdFEvWc1Gs77t3Zi1ebeVw9KTnSD4pFpkUQmaLYpL3/jHRr7ZzIEr1Zt9sI7rsd8qIYjPrzUTFUmuUWnbBK3Ry5YNKdNGFccnSw4t/W3lTSKTcXjx8tnWPo834XK5+nGN6ZYI4ZzcPhdGX6fXg7KO0ySITZloq15aGdx9izSsfltaB6Twsn2GXL1IaeVOLgSfMs4xWbWpB+pLYPJ2Q3C74SKkgBV1ZmaxWpKRsdEbVlP8stV9TPqy5hJC0P7fWRdmmwz2XZEgSWtg7WLASUtm05jrEQ7dvNDxyoW7PX2ZWEtqStHuLiscYlW04Ng6SoTbtOywiSBqaO4m+yPnoKG2CJg4j9DRGJ7Sx+i2rPJLsHDX0DOnozumrvAjSGLeFFy3uWKiMbpeWmdtbWbt45eCgGCSIJL0clqNikKnD3noJ7+JgvEM0epGJPk1En+ijX6pFv9iOfrv0Bhve2I8soj90GnxsOOUxQt/g4Df6o/uu+JJbhVBTmZdR8SX68ln8BdD4S9i9JoR7TZN8mxB6bSR5nG0k8TcCxd/KFX0z3ij6dspR/A2xFi3Ne94tzSOVJvt7/k0z1o33SizuundmvmZ7RUSJ+s6Nggt5kbKMol79c9vlRxc/+AmNSr6+O1HNisWxPF/OKo+HYnalPVXGbJzqQz/TLbcbreXlyplSUqer7/eZClZBFNue6bOVuHWd7uRk2fQ+w5TPnTuVXAZgXsg7Tdsy7elQnjHxUJXdKnvcpP+ZWyepvTy9YTERVdYZvFcWFqu06V6oGZTPev7VlsdIyWc3TuvgY6jMcdN4PrKeUirtcsohdA+lmVFfTrKfdtuOY9hKYw45x41jWktqo6VBh9BUGcaF8ymu3Jh8GHDGV4cuO7i/LE0M3doXnkqSD5l/3dtmHjq22DwPTVpYDRxk6YPv/zamk2A8WAdN2e0jTCU15j1QBvskJQ2y0GTqupMHrEuagHXGBPAh29J2nWBXOX0upqr72Mc3w2Ad3izUey/Th8ZBgPV65+aY25gQ2vUvPBFTCnt48qTtYmEWmsyQOrg0qN2GGsHcmhWfwNHfZqf9JZdmxSdA333p4btT2T6wxjwqsfiwir5VYI1rdJfg4GFcmc6uoBz0alQaYKJQHx6HFS0ZglrMUBb0k7zkLLxMSLcC/GwCVIB3KEja/TRE0slOUB3qdF/p6OfX2kVDUPeYaAHpqSOS1u0hbev93v4L8C5h9A6OxF/C/DUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADg7fgDyhYmCCEbhAQAAAAASUVORK5CYII=';

            setUser(response.data.ObjRetorno);
            window.localStorage.setItem(
                'token',
                JSON.stringify(response.data.ObjRetorno)
            );

            history.push('/');
        } catch (err) {
            messageStore.addError(err);
        }
    }

    return (
        <div id="login">
            <div className="container">
                <form onSubmit={handleOnSubmit}>

                    <a className='logo'></a>            

                    <h1>Entrar</h1>
                    <h2>Acompanhe seu crescimento e converta seus leads</h2>

                    <label
                        className={`option ${
                            option === 'cliente' ? 'active' : 'inactive'
                        }`}
                    >
                        <input
                            checked={option === 'cliente' ? true : false}
                            type="radio"
                            name="tipo"
                            value="sgmt"
                            onChange={() => setOption('cliente')}
                        />
                        <div>
                            <b>Usuário Cliente</b>
                            <span>
                                Acompanhe seu crescimento e converta seus leads.
                            </span>
                        </div>
                    </label>

                    <label
                        className={`option ${
                            option === 'sgmt' ? 'active' : 'inactive'
                        }`}
                    >
                        <input
                            checked={option === 'sgmt' ? true : false}
                            type="radio"
                            name="tipo"
                            value="sgmt"
                            onChange={() => setOption('sgmt')}
                        />
                        <div>
                            <b>Usuário SGMT</b>
                            <span>
                                Acompanhe seu crescimento e converta seus leads.
                            </span>
                        </div>
                    </label>

                    <div className="hr-green" />

                    <label className="input">
                        <span>Email / Username</span>
                        <input
                            type="email"
                            placeholder="seuemail@website.com.br"
                            value={Login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </label>

                    <label className="input">
                        <span>Senha</span>
                        <input
                            type="password"
                            placeholder="min. 8 caracteres"
                            value={Senha}
                            onChange={(e) => setSenha(e.target.value)}
                        />
                    </label>

                    <button type="submit">
                        {load === true ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                                    opacity=".5"
                                    fill="currentColor"
                                />
                                <path
                                    d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                                    fill="currentColor"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 12 12"
                                        to="360 12 12"
                                        dur="1s"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                        ) : (
                            'Entrar'
                        )}
                    </button>

                    <p className="terms">
                        Seguindo em frente, você concordará com os{' '}
                        <a>Termos de Uso</a> da plataforma Prefeitura de mesquita e suas <a>Condições de Privacidade</a>. Usamos
                        cookies essencias e você tem acesso ao nosso{' '}
                        <a>Aviso sobre Cookies</a> para conferir mais
                        informações.
                    </p>

                    <a className="signup">
                        <span>Primeira vez por aqui?</span>
                        <b>Crie uma nova conta Segmenta</b>
                    </a>

                    <div className="footer">
                        © 2021 Segmenta. Todos os direitos reservados.
                    </div>
                </form>

                <div className="bg"></div>
            </div>
        </div>
    );
}
